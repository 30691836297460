import { Box, Button, IconButton, Typography, List, ListItem, ListItemText } from "@mui/material";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import Plans from "../plans";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState, useContext } from "react";
import axios from "axios";

import ScrollToTopOnMount from "../scrolltoview";
import cancel from "../../images/cancel.svg";
import { getUserDetails } from "../../utils/getUserDetails";

export default function Overview({ updateSnackbar }) {
    const [currency, setCurrency] = useState('')
    const [acctBalance, setAcctBalance] = useState('')
    const [login, setLogin] = useState('')
    const [isVisible, setIsVisible] = useState(true);

    const navigate = useNavigate()

    useEffect(() => {
        // TODO: Verify it is valid from the backend
        if (!sessionStorage.getItem('session')) {
            sessionStorage.clear()
            return navigate('/Signin');
        }

        const detailsToFetch = ["creditBalance", "currency"];
        getUserDetails(detailsToFetch)
        .then((details) => {
            setAcctBalance(details.creditBalance);
            setCurrency(details.currency);
            setLogin(sessionStorage.getItem('login'))
        })
        .catch((err) => {
            if (err.errorCode == 1) {
                sessionStorage.clear()
                navigate('/Signin')
            }

            updateSnackbar(err.message, 'red');
        })
    }, []);

    return (
        <Box component='div'
             sx={{ mt: {xs: 0, sm: -3}}}>
             <ScrollToTopOnMount />   
             {isVisible &&
             <Box sx={{ bgcolor: '#115BC71A', borderRadius: 3, py: 1, px: 1.5, position: 'relative' }}>
                <Box px={3}  sx={{ display: 'flex', alignItems: 'center', borderRadius: 3, py: 1 }}>
                    <Box>
                        <Typography   sx={{fontWeight: '700', color: '#115BC7'}} >Make voice calls with our mobile app. </Typography>
                        <Typography   variant="body2"  sx={{color: '#115BC7'}}>Get the full experience by downloading our app. Enjoy exclusive voice call features on-the-go</Typography>
                    </Box>
                    <RouterLink to='/S' 
                                style={{ textDecoration: 'none', 
                                          padding: '0px', 
                                          marginTop: '-40px',
                                          marginLeft: 'auto'  }}>
                        <Button  color='primary' variant="contained"
                        sx={{  mt: 5, py: 1, px: {md: 2}, backgroundColor: '#115BC7', textTransform: 'none' }}>
                            Download Now
                        </Button>
                    </RouterLink>
                </Box>
                <Box component={'img'} onClick={() => setIsVisible(false)} src={cancel} position={'absolute'} top={'-5px'} right={'-5px'} />
            </Box>
            }
            <Typography mt={1} variant="h5" sx={{ py: {xs: 3, sm: 1}}} fontWeight={700}>Hello, {login}</Typography>
            <Box sx={{ bgcolor: 'white', borderRadius: 3, py: 1, px: 1.5 }}>
                <Box px={3}  sx={{ display: 'flex', alignItems: 'center', bgcolor: '#E7F7E1', borderRadius: 3, py: 1 }}>
                    <Box>
                        <Typography variant="body2" >Wallet Balance</Typography>
                        <Typography variant="h5" fontWeight={700}>{currency} {acctBalance}</Typography>
                    </Box>
                    <RouterLink to='/AddFunds' 
                                style={{ textDecoration: 'none', 
                                          padding: '0px', 
                                          marginTop: '-40px',
                                          marginLeft: 'auto'  }}>
                        <Button  color='success' variant="contained"
                        sx={{  mt: 5, py: 1, px: {md: 7}, backgroundColor: '#3EBF0F', textTransform: 'none' }}>
                            Add Funds
                        </Button>
                    </RouterLink>
                </Box>
            </Box>
            <Typography variant="h6" sx={{ pt: {xs: 3, sm: 2}}} pb={3} textAlign='center'  fontWeight={700}>Available Calling Plans</Typography>
            <Box textAlign='center' >
                {currency &&
                    <Plans updateSnackbar={updateSnackbar} />
                }
            </Box>
            
            <List sx={{ width: '100%', mt: 5, bgcolor: 'transparent' }}>
                <RouterLink to='/Dashboard' state={{page: 'myaccount' }}  style={{ textDecoration: 'none' }}>
                    <ListItem
                    key={1}
                    sx={{ my: 2, bgcolor: 'background.paper', borderRadius: 3, py: 2 }}
                    secondaryAction={
                        <IconButton aria-label="comment">
                            <RouterLink to='/Dashboard' state={{page: 'myaccount' }}  style={{ textDecoration: 'none' }}>
                                <ArrowForwardIosIcon sx={{color:"black"}} />
                            </RouterLink>
                        </IconButton>
                    }
                    >
                        <ListItemText primary="My Account" sx={{color:"black"}} />
                    </ListItem>
                </RouterLink>
                <RouterLink to='/Dashboard' state={{page: 'contactsupport' }}  style={{ textDecoration: 'none' }}>
                    <ListItem
                    key={2}
                    sx={{ my: 2, bgcolor: 'background.paper', borderRadius: 3, py: 2 }}
                    secondaryAction={
                        <IconButton aria-label="comment">
                            <RouterLink to='/Dashboard' state={{page: 'contactsupport' }}  style={{ textDecoration: 'none' }}>
                                <ArrowForwardIosIcon sx={{color:"black"}}  />
                            </RouterLink>    
                        </IconButton>
                    }
                    >
                        <ListItemText primary="Contact Support" sx={{color:"black"}} />
                    </ListItem>
                </RouterLink>
                </List>
        </Box>
    )
}