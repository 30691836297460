import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { CardMedia, Typography, Box, Button } from '@mui/material';
import { IOSSwitch } from '../utils/iosSwitch';
import { getUserDetails } from '../utils/getUserDetails';

import us from '../images/US.png';
import ng from '../images/NG.png';

import { Link as RouterLink } from 'react-router-dom';

// TODO: Refactor code to use class blueprint for creation of plan objects.

const dollarPlansArray = [
  {
    price: 5,
    formattedPrice: '$5',
    description: 'FOR 150MINS',
    planId: '16',
    currency: 'USD',
    flag: us,
    note: "",
    planCode: "PLN_yi369xn8t7gf0tj"
  }, {
    price: 10,
    formattedPrice: '$10',
    description: 'FOR 400MINS',
    planId: '17',
    currency: 'USD',
    flag: us,
    note: "+ dedicated phone number for incoming/outgoing calls",
    planCode: "PLN_n5s276dhvr2gn56"
  }
]


const nairaPlansArray = [
  {
    price: 1000,
    formattedPrice: 'N1000',
    description: 'FOR 110MINS',
    planId: '18',
    currency: 'NGN',
    flag: ng,
    note: "",
    planCode: "PLN_mb7bxritd9izoh5"
  }, {
    price: 5000,
    formattedPrice: 'N5000',
    description: 'FOR 650MINS',
    planId: '19',
    currency: 'NGN',
    flag: ng,
    note: "",
    planCode: "PLN_iu5czhcyx1l4dmw"
  },  {
      price: 10000,
      formattedPrice: 'N10000',
      description: 'FOR UNLIMITED CALLS' ,
      planId: '20',
      currency: 'NGN',
      flag: ng,
      note: "",
      planCode: "PLN_990g5bwo15wwpad"
  },  {
    price: 300,
    formattedPrice: '300',
    description: '1 DAY TEST PLAN' ,
    planId: '21',
    currency: 'NGN',
    flag: ng,
    note: "",
    planCode: "PLN_dpf4mgc1qduyfmf"
}
]


export default function Plans({updateSnackbar}) {
  const [currency, setCurrency] = useState()
  const [plansArray, setPlansArray] = useState([])

  const updatePlan = () => {
    currency === 'NGN' ? setPlansArray(nairaPlansArray) : currency === 'USD' && setPlansArray(dollarPlansArray);
  }

  useEffect(() => {
    const detailsToFetch = ["currency"];
    getUserDetails(detailsToFetch)
    .then((details) => {
        setCurrency(details.currency)
        updatePlan()
    })
    .catch((error) => {
        if (error.errorCode == 2) {
          setPlansArray(dollarPlansArray)
        } else {
          updateSnackbar("Network error, please refresh the page." , 'red')
        }
    });
  }, [currency]);  

  

  const handleChange = (event) => {
    if (event.target.checked) {
      setPlansArray(nairaPlansArray)
    } else {
      setPlansArray(dollarPlansArray)
    }
  }
  return (
    <>
      {!currency &&
      <Box display='inline-flex' alignItems='center' py={3} gap={2}>
        <span>DOLLAR PLANS</span>
          <IOSSwitch 
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
             />
        <span>NAIRA PLANS</span>
      </Box>
      }
      

      <Grid container spacing={5} sx={{ px: { xs: 1, sm: 5}}} >
        {plansArray.map((plan) => {
          return (
          <Grid
                item
                key={plan.planId}
                xs={12}
                sm={6}
                md={4}
                
              >
                <Card  sx={{ textAlign: 'center', boxShadow: 5, pb: 2, pt: 2, borderRadius: 3, height: '400px', position: 'relative' }} >
                  <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <CardMedia
                      component='img'
                      alt={plan.currency}
                      src={plan.flag}
                      sx={{  width: '100px' }}
                      />
                  </Box>
                  <Typography variant='h5' fontWeight={700} pt={1}>{plan.currency}</Typography>
                  <CardContent sx={{ pt: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        
                      }}
                    > 
                      <Box>
                          <Box>
                            <Typography component="h2" fontWeight={700} variant="h3" color="#929292">
                              {plan.formattedPrice}
                            </Typography>
                            <Typography variant="subtitle1" fontWeight={700} color="#929292">
                              {plan.description}
                            </Typography>

                            <Typography variant="subtitle2" color="#929292">
                              {plan.note}
                            </Typography>                            
                          </Box>
                      </Box>                  
                    </Box>

                  </CardContent>

                  <Box sx={{ position: 'absolute', width: '100%', bottom: '10px'}} >
                    <CardActions sx={{ 'display': 'flex', justifyContent: 'center'}}>
                      <RouterLink to="/Dashboard" 
                      state={{ page: 'confirmBuyPlan', 
                      plan: plan.planId, price: plan.price, 
                      formattedPrice: plan.formattedPrice, 
                      description: plan.description,
                      currency: plan.currency,
                      planCode: plan.planCode
                    }} 
                      style={{ textDecoration: 'none', width: '100%' }}>
                        <Button color='success' 
                                variant="contained" 
                                sx={{ mt: 2,  mx: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none',
                                      width: '50%'  }}>
                          Buy Now
                        </Button>
                      </RouterLink>
                    </CardActions>
                    <Typography variant='subtitle2' color='#929292'>
                      All plans valid for 30days
                    </Typography>
                  </Box>
                </Card>
              </Grid>
        )})}
      </Grid>
    </>          
  )
}
  