import { Paper, Typography, Box, Stack, FormControl, Snackbar, ButtonGroup } from "@mui/material";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";

import React, { useContext, useState } from "react";

import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';

import axios from "axios";

import { BACKEND, BACKEND_ENDPOINTS, BASE_URL_VOIPSWITCH, ENDPOINTS} from "..";
import ScrollToTopOnMount from "./scrolltoview";
import { sha1 } from "js-sha1";
import { NewUserContext } from "../pages/auth";
import { getTechPrefix } from "../utils/getTechPrefix";


export default function ProfileName(props) {
    const [displaySignin, setDisplaySignin] = useState('none')
    const [firstName, setFirstName] = useState('')
    const [snackMessage, setSnackMessage] = useState('');
    const [open, setOpen] = useState(false);
    const { userDetails, setUserDetails } = useContext(NewUserContext);   

    const updateSnackbar = props.updateSnackbar
    const toggleSpinner = props.toggleSpinner

    const [snackColor, setSnackColor] = useState('red')

    const number = userDetails['number']
    const country = userDetails['country']
    const email = userDetails['email']

    const navigate = useNavigate();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpen(false);
    };

    const handleSumbit = (e) => {
        e.preventDefault()
        toggleSpinner(true)
        
        axios.post(`${BACKEND}${BACKEND_ENDPOINTS['signup']}`, {
            _at: userDetails.resellerToken || "",
            login: number,
             password: props.password,
             webPassword: "",
             address: "",
             zipCode:  "",
             city: "",
             taxID: "",
             eMail: email,
             phoneNumber: "",
             mobileNumber: "",
             tariffId: country == "+234" ? 107 : 93,
             accountState: 0,
             generateInvoice: false,
             sendInvoice: false,
             country: "",
             state: "",
             firstName: firstName,
             lastName: "",
             callsLimit: 0,
             postPaid: false,
             postPaidCredit: 0,
             postPaidDescription: "",
        }, { headers: { 
            "x-api-key": process.env.REACT_APP_SIGNUP_KEY}
        })
        .then((res) => { 
            axios.post(`${process.env.REACT_APP_EMAILAPP}nt-mail`, {
                "email_template": "template4",
                "user": number,
                "to_email": email
            }, { headers: { 
                "Authorization": process.env.REACT_APP_EMAILAPP_KEY}
            })
            .then((res) => {

            }).catch((err) => {
            })

            let selectedNumber = "02018888411"
            if (res['data']['idClient'] != 0) {
                let techPrefix = getTechPrefix(selectedNumber)

                // added here because of asynchronity
                axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['setDid']}`, {
                    clientId: res['data']['idClient'],
                    techPrefix: techPrefix,
                  })
                .then((res) => {
                    if (res['data']['status'] === -1) {
                      throw new Error("error while linking number")
                    }
                    else {
                      props.updateSnackbar("outgoing number linked successfully", "grey")
                    } 
                })
                .catch((err) => {
                    props.updateSnackbar(err.message, "grey")
                })    

                // Log user in
                axios.post(`${BACKEND}${BACKEND_ENDPOINTS['login']}`, {
                    login: number,
                    password: props.password,
                }, { headers: { 
                    "x-api-key": process.env.REACT_APP_LOGIN_KEY}
                })
                .then((res) => { 
                    if (res.data.status != "successful") {
                        throw new Error(res.data.responseStatus.message);
                    }
                    sessionStorage.setItem('session', res.data.session)
                })
                .catch((err) => {
                    updateSnackbar(err.message, 'red');
                })
                
            } else {
                setOpen(true)
                setSnackMessage(res['data']['responseStatus']['message'])

                setDisplaySignin('visible')
            }

            setOpen(true)    
            setSnackColor("green")
            setSnackMessage("Account created successfully")

            navigate("/Auth", {state: { page: "accountCreated"}})
            toggleSpinner(false)
        })
        .catch((err) => {
            toggleSpinner(false)  
            updateSnackbar(err.message, 'red');
        })
    }    

    return (
        <Paper component="div"
               sx={{ display: 'flex', flexDirection: 'column',
               py: 10, px: 4, mt: 3, width: {xs: '70vw', sm: '400px'},
               borderRadius:4 }}>
            <ScrollToTopOnMount />
            <Stack direction='row' spacing={1}>
                <Box bgcolor="#BFBFBF" width='52px' height='2px'></Box>
                <Box bgcolor="#BFBFBF" width='52px' height='2px'></Box>
                <Box bgcolor="#BFBFBF" width='52px' height='2px'></Box>
                <Box bgcolor="#BFBFBF" width='52px' height='2px'></Box>
            </Stack>

            <Typography variant="h5" fontWeight={700} mt={3.5}>Profile Name</Typography>
            <Typography variant="body2" mt={1} color="#2E368F">
                Fill in your profile name
            </Typography>

            <FormControl sx={{ pt: 3}}>
                <TextField id="outlined-basic" 
                            onChange={(e) => {setFirstName(e.target.value)}} label="Profile Name" variant="outlined" />
            </FormControl>

            <Stack direction='column' pt={1.5}>
                <ButtonGroup orientation="vertical">
                    <Button onClick={(e) => handleSumbit(e)} color='success' variant="contained"
                    sx={{  mt: 7.5, mb: 3, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                        Save
                    </Button>
                    
                    <Button onClick={(e) => handleSumbit(e)} color='success' variant="outlined"
                    sx={{  py: 1.5, color: '#3EBF0F', borderColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                        Skip
                    </Button>
                </ButtonGroup>
                </Stack>
                <Typography mt={5} visibility={displaySignin}>User already exists 
                    <RouterLink to="/Signin" > sign in</RouterLink>
                </Typography>

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    message={snackMessage}
                    sx={{
                        "& .MuiSnackbarContent-root": {
                          backgroundColor: `${snackColor}`,
                        },
                      }}
                />
        </Paper>
    )
}