import successIcon from '../images/successIcon.svg';
import failedIcon from '../images/failed.svg';
import { Paper, Box, Stack, Typography, Button } from '@mui/material';

import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { isEmptyObject } from '../utils/isEmptyObject';
import Header from '../components/header';

export function Status(props) {
    const location = useLocation();

    if (isEmptyObject(props)) {
        props = location.state;
    }
    
    return (
        <>
            <Header />
            <Paper component="div"
            sx={{ display: 'flex', flexDirection: 'column',
            pt: 3, pb: 10, mt: 15, mx: 'auto', width: {xs: '90vw', sm: '600px'},
            borderRadius:4 }}>

                <Box display='flex' px={3} alignItems='center'>
                    <RouterLink to={-1} state={{page: 'overview' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
                        <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
                    </RouterLink>
                    <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>{props.title}</Typography>
                </Box>
                <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

                <Box px={3}>
                    <Stack direction='column' pt={6} textAlign="center">
                        <Box component='img'
                                width={120}
                                mx='auto'
                                src={props.boolStatus ? successIcon : failedIcon } />
                        <Typography variant="h5" mt={2} fontWeight={700}>{props.status}</Typography> 
                        <Typography variant="body2">{props?.error}</Typography>
                        <RouterLink to='/Dashboard' state={{page: 'overview' }}  style={{ textDecoration: 'none' }}>
                            <Button  color='success' variant="contained"
                            sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                                Okay, Thank you
                            </Button>
                        </RouterLink>
                    </Stack>
                </Box>
            </Paper>
        </>
    )
}