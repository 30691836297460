
import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Landing from './pages/landing';
import Signin from './pages/signin';
import Auth from './pages/auth';
import Otp from './components/otp';
import S from './components/s';

import reportWebVitals from './reportWebVitals';


import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';


import './fonts/Helvetica-Font/Helvetica.ttf';
import CreatePassword from './components/createPassword';
import ProfileName from './components/setProfileName';
import Dashboard from './pages/dashboard/dashboard';
import NotFound from './pages/notFound';
import PrivateRoute from "./components/PrivateRoute"
import PrivateRoute2 from "./components/PrivateRoute2"

import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { PrivacyPolicy } from './privacyPolicy';
import { Box } from '@mui/material';
import wa_ic from './images/whatsapp.svg';
import AddFunds from './components/dashboard/checkouts/addFunds';
import { Status } from './pages/status';
import UserAddfuns from './components/dashboard/checkouts/UserAddfuns';
import {Snackbar, CircularProgress, Modal, Typography} from '@mui/material';
import { getUserDetails } from './utils/getUserDetails';

loadProgressBar();

// Context
export const UserContext = createContext();

export const BASE_URL_VOIPSWITCH = process.env.REACT_APP_VOIPSWITCH
export const BASE_URL_VOIPSWITCH2 = process.env.REACT_APP_VOIPSWITCH2
export const BACKEND = process.env.REACT_APP_BACKEND
export const EMAILAPP = process.env.REACT_APP_EMAILAPP

export const BACKEND_ENDPOINTS = {
  'signup': 'api/register',
  'login': 'api/login',
  'getuserdetails': 'api/getuserdetails',
  'addfunds': 'api/addfunds',
  'getStripeSecret': 'api/create-stripe-payment-intent',
  'sendotp': 'api/otp',
  'verifyotp': 'api/verifyotp',
  'checkuser': 'api/checkuser',
  'getnumber': 'api/getnumber',
  'addnumber': 'api/addnumber',
  'initializepayment': 'api/payment/initialize',
  'getsubscriptions': 'api/payment/getsubscriptions',
  'cancelsubscription': 'api/payment/cancelsubscription'
}

export const ENDPOINTS = { 
  // Voipswitch
  'login': 'admin.client.login',
  'editProfile': 'admin.client.personal.update',
  'changePassword': 'admin.client.password.set',
  'getPassword': 'admin.client.password.get',
  'signup': 'admin.retail.create',
  'getBalance': 'admin.client.balance',
  'getPlansList': 'admin.plans.list',
  'getPlanData': 'admin.plan.get',
  'addPlan': 'admin.client.plan.add',
  'addFunds': 'admin.payment.add',
  'setDid': 'admin.client.techprefix.set',
  'getNumberList': 'admin.did.local.number.list',
  'getClientInfo': 'json/syncreply/admin.client.personal.get',
  'getClientPlanList': 'admin.client.plan.list',
  'getPlanDetails': 'admin.plan.get',
  'getTechPrefix': 'admin.client.techprefix.get',

  // Voipswitch 2
  'buyNumber': 'client.dids.numbers.buy',
  'getClientDid': 'client.dids.numbers.get',  

  // emailing
  'accordMail': 'ac-mail',
  'ntMail': 'nt-mail',

  // Nativetalk multipurpose backend
  'sendNigerianOtp': 'otp',
  'cancelSubscription': 'cancel-subscription',
  'createStripeCustomer': 'stripe-customers',
  'createStripeSubscription': 'stripe-subscriptions',
}

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      snackMessage: '',
      snackColor: '',
      open: false,
      stripePromise: null,
      showSpinner: false
    };
  }

  componentDidMount = () => {
    this.getResellerKey()
  }

  getResellerKey = () => {
    const detailsToFetch = ["reseller_public_key"];
    getUserDetails(detailsToFetch)
      .then((details) => {
          this.setState({stripePromise: loadStripe(details.reseller_public_key)})
      })
      .catch((error) => {
          if (error.errorCode == 1) {
              sessionStorage.clear()
              this.props.history.pushState('/Signin')
          }
          console.error(error);
      });
  }

  updateSnackbar = (message, color) => {
    this.setState({
      snackMessage: message,
      snackColor: color,
      open: true,
    });
  };

  closeSnackbar = () => {
    this.setState({ open: false });
  };

  toggleSpinner = (spinnerState) => {
    this.setState({
      showSpinner: spinnerState,
    });
  };
  

  render() {
    return (
      <Elements stripe={this.state.stripePromise}>  
      <Router>
        <div style={{ position: 'relative' }}>
          <Routes>
            <Route path="/" element={<Landing updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner} />}></Route>
            {/* Private Route */}
            <Route
              path="/AddFunds"
              element={
                <PrivateRoute>
                  <AddFunds updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner} />
                </PrivateRoute>
              }
            />
            <Route
              path="/AddFunds/:username"
              element={
                <PrivateRoute2>
                  <UserAddfuns updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner} />
                </PrivateRoute2>
              }
            />           
            {/* BuyPlan */}
            <Route path="/BuyPlan/Status" element={<Status />}></Route>
            <Route path="/s" element={<S updateSnackbar={this.updateSnackbar}  toggleSpinner={this.toggleSpinner}/>}></Route>
            <Route path="/Signin" element={<Signin updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner} getResellerKey={this.getResellerKey} />}></Route>
            <Route path="/Otp" element={<Otp updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner} />}></Route>
            <Route path="/CreatePassword" element={<CreatePassword updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner}/>}></Route>
            <Route path="/ProfileName" element={<ProfileName updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner} />}></Route>
            <Route path="/Auth" element={<Auth updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner}/>}></Route>

            {/* For resellers: /Auth?reseller={id} */}
            <Route path="/Dashboard" element={<Dashboard updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner} />}></Route>
            <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner} />} />
            <Route path="*" element={<NotFound updateSnackbar={this.updateSnackbar} toggleSpinner={this.toggleSpinner} />} />
          </Routes>
          <a href={`https://wa.me/${process.env.REACT_APP_INTL_SUPPORT}`}>
            <Box component="img" width={50} sx={{ zIndex: '1500', bottom: '10px', right: '50px' }} position="fixed" src={wa_ic} />
          </a>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.open}
            autoHideDuration={2000}
            message={this.state.snackMessage}
            sx={{
              '& .MuiSnackbarContent-root': {
                backgroundColor: `${this.state.snackColor}`,
              },
            }}
            onClose={this.closeSnackbar}
          />

          {this.state.showSpinner &&
          <Box component='div' display={'flex'} alignItems={'center'} justifyContent={'center'} 
          width={'100%'}
          sx={{ position: 'absolute', top: '45%', }} >
            <CircularProgress color="success" />
          </Box>}

          {/* <Modal
            open={true}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography>
            </Box>
          </Modal> */}
          
        </div>
      </Router>
      </Elements>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

reportWebVitals();
